import React from "react";
import ErrorSec from "containers/Error";
import { ResetCSS } from "common/assets/css/style";

const NotFoundPage = () => (
  <>
    <ResetCSS />
    <ErrorSec></ErrorSec>
  </>
);

export default NotFoundPage;
